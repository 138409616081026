<template>
  <div>
    <router-view />
  </div>
</template>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

:root {
  min-height: 100vh;
  background-color: rgba(241, 243, 250, 1);
  color: rgba(18, 3, 58, 0.80);
}
</style>
